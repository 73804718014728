import { gql } from '@apollo/client'
export interface RolesProps {
  id: string
  name: string
}
export interface RolesDataProps {
  id: string
  name: string
  subRoles: [RolesProps]
}

export const ALL_ROLES = gql`
  query roles {
    roles {
      id
      name
      subRoles {
        id
        name
      }
    }
  }
`
