import { ReactElement, useEffect, useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { Heading, Box, Button, Spinner, Flex, Tooltip } from '@chakra-ui/react'
import { SurveyList } from '../components/SurveyList'
import { useQuery } from '@apollo/client'
import { ALL_PATTERISTO_FOR_ORGANIZATION } from '../queries/all_patteristo_for_organization'
import { SINGLE_ORGANIZATION } from '../queries/organization'
import { useOrganization } from '../contexts/OrganizationContext'
import { useAuthState } from '../contexts/Firebase/Context'
import { ME } from '../queries/me'
import { FaInfoCircle } from 'react-icons/fa'

function AdminLanding(props): ReactElement {
  const { organization, setOrganization } = useOrganization()

  const { user } = useAuthState()
  const history = useHistory()
  const { data, refetch } = useQuery(ME)
  const [userData, setUserData] = useState()

  const surveyData = useQuery(ALL_PATTERISTO_FOR_ORGANIZATION, {
    variables: { organizationId: organization },
    pollInterval: 30000,
  })

  useEffect(() => {
    const organisaatio = localStorage.getItem('organisaatio')
    if (organisaatio) setOrganization(organisaatio)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    surveyData.refetch()
  }, [organization])

  useEffect(() => {
    if (data?.me) {
      setUserData(data.me)
    }
  }, [data])

  const organizationData = useQuery(SINGLE_ORGANIZATION, {
    variables: { organizationId: organization },
  })

  useEffect(() => {
    if (organizationData.data) {
      props.currentOrganisation(organizationData.data.organization)
    }
  }, [organizationData])
  if (surveyData.loading || organizationData.loading) {
    return (
      <Box textAlign="center">
        <Spinner size="xl" />
      </Box>
    )
  }

  return (
    <Box textAlign="left">
      <Heading as="h1">Kyselyt</Heading>
      <Flex my={6}>
        <Box pr={1}>
          <Button
            aria-label="Luo uusi kysely"
            as={RouterLink}
            to="/admin/createsurvey"
            size="sm"
            variant="primary"
          >
            Luo uusi kysely
          </Button>
        </Box>
        <Tooltip
          label="Anna tarvittavat tiedot ja luo uusi kysely. HUOM! Luodut kyselyt pystyy poistamaan ainoastaan Yhteistoimintavaa’an ylläpitäjä."
          placement="top"
        >
          <span>
            <FaInfoCircle />
          </span>
        </Tooltip>
      </Flex>
      <SurveyList user={userData} surveys={surveyData.data} />
    </Box>
  )
}

export default AdminLanding
