import { Link } from '@chakra-ui/layout'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'

const QrPage = props => {
  const params = useParams<Record<string, string>>()
  return (
    <>
      <QRCode
        style={{ margin: 'auto' }}
        value={`${process.env.REACT_APP_OWN_URI}${params.id}/${params.pw}`}
      />
      <Link as={RouterLink} target="_blank" to={`/${params.id}/${params.pw}`}>
        {process.env.REACT_APP_OWN_URI}
        {params.id}/{params.pw}
      </Link>
    </>
  )
}

export default QrPage
