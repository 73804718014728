import { Th } from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons'

const { useCallback, useEffect } = require('react')

const AdminPanelOrganizationSorter = props => {
  const onClick = useCallback(() => {
    props.sorting.sorter = props.DBName
    if (props.sorting.sorter === props.DBName) {
      if (props.sorting.direction === 'ASC' || props.sorting.direction === '') {
        props.setSorting({ sorter: props.DBName, direction: 'DESC' })
      }
      if (props.sorting.direction === 'DESC') {
        props.setSorting({ sorter: props.DBName, direction: 'ASC' })
      }
    }
  }, [props])
  const Arrows = useCallback(() => {
    if (props.sorting.sorter === props.DBName) {
      return (
        <>
          {props.sorting.direction === 'ASC' && <ArrowUpIcon />}
          {props.sorting.direction === 'DESC' && <ArrowDownIcon />}
        </>
      )
    } else
      return (
        <>
          <ArrowUpIcon />
          <ArrowDownIcon />
        </>
      )
  }, [props])
  return (
    <Th style={{ cursor: 'pointer' }} onClick={() => onClick()}>
      {props.children}
      <Arrows />
    </Th>
  )
}
export default AdminPanelOrganizationSorter
