import { Box, Text } from '@chakra-ui/react'
import { ResultChart } from './ResultChart'
import { ResultTable } from './ResultTable'

export function ReportItem(props) {
  return (
    <>
      <ResultTable data={props.queryData.data} id={props.id} />
      <Box h="250px">
        <ResultChart data={[{ data: props.queryData.data }]} roles={props.queryData.roles} />
      </Box>
      {props.queryData.data.map((item, index) => (
        <Text key={index} fontSize="sm" fontStyle="italic">
          {item.role.name}: keskiarvo{' '}
          {item.average % 1 === 0 ? item.average : item.average.toFixed(2)}, keskihajonta{' '}
          {item.errorMargin % 1 === 0 ? item.errorMargin : item.errorMargin.toFixed(2)}
        </Text>
      ))}
    </>
  )
}
