import { useHistory } from 'react-router-dom'
import { Button } from '@chakra-ui/react'

const AdminPanelButton = () => {
  const history = useHistory()
  return (
    <Button
      aria-label="Admin Panel"
      variant="ghost"
      colorScheme="brand.secondary"
      onClick={() => history.push('/admin/create')}
    >
      Admin panel
    </Button>
  )
}

export default AdminPanelButton
