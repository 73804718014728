import { gql } from '@apollo/client'

export const FETCH_ORGANIZATION = gql`
  query checkOrganizationStatusQuery($ytunnus: String!) {
    checkOrganizationStatus(data: { ytunnus: $ytunnus }) {
      valid
      nimi
    }
  }
`
