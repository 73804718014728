import { gql } from '@apollo/client'

export const COMPARE_FILTERS_ANSWERS = gql`
  query getCompareAnswers(
    $collectionId: String!
    $filters1: FilterInput!
    $filters2: FilterInput!
  ) {
    compare(id: $collectionId, filters1: $filters1, filters2: $filters2) {
      index
      otsikko
      aliotsikko
      kysymys
      roles {
        name
      }
      data {
        role {
          id
          name
          parentRole {
            id
            name
          }
        }
        values {
          key
          count
        }
        average
        errorMargin
        roleCount
        comparation
      }
    }
  }
`
