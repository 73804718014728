import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { useEffect, useState } from 'react'

import MultiSelect from './MultiSelect'
import { FormattedMessage, useIntl } from 'react-intl'
import { Label } from './Label'
import Maakunnat from '../enums/Maakunnat'
import { CREATE_USER } from '../mutations/create_user'
import { ADD_USER_TO_ORGANIZATION } from '../mutations/add_user_to_organization'
import { FETCH_ORGANIZATION } from '../queries/check_organization'
import { USER_REGISTER } from '../mutations/user_register'
const content = <Flex py={4}>asd</Flex>

export const RegisterModal = props => {
  const refetch = props.refetch
  const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER)

  const [registerUser] = useMutation(USER_REGISTER)
  const [addUserToOrganization] = useMutation(ADD_USER_TO_ORGANIZATION)
  const intl = useIntl()

  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  })
  const [newUser, setNewUser] = useState(0)
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(0)
  const [isActive, setIsActive] = useState(true)
  const [etunimi, setEtunimi] = useState('')
  const [sukunimi, setSukunimi] = useState('')

  const [maakunnat, setMaakunnat] = useState<Maakunnat>()
  const [nimi, setNimi] = useState('')
  const [koko, setKoko] = useState(0)

  const [ytunnus, setYtunnus] = useState('')
  const [fetchYtunnus, { data: fetchYtunnusData }] = useLazyQuery(FETCH_ORGANIZATION)

  const toast = useToast()

  const sendCreateUser = async () => {
    try {
      await createUser({
        variables: {
          //@ts-ignore
          email,
          isAdmin,
          etunimi,
          sukunimi,
          isActive,
        },
      })
    } catch (e) {}
  }

  const sendCreateOrganization = async () => {
    try {
      await registerUser({
        variables: {
          maakunta: maakunnat,
          koko,
          ytunnus,
          etunimi,
          sukunimi,
        },
      })
      await props.fetchMe()
    } catch (e) {
      toast({
        title: intl.formatMessage({
          id: 'create_organization_error',
          defaultMessage: 'Jokin meni vikaan.',
        }),
        status: 'error',
        duration: 5000,
      })
    }
  }

  useEffect(() => {
    if (fetchYtunnusData?.checkOrganizationStatus?.valid) {
      setNimi(fetchYtunnusData.checkOrganizationStatus.nimi)
    }
  }, [fetchYtunnusData])

  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (props.open && props.email) {
      setEmail(props.email)
      onOpen()
    }
  }, [props.open, props.email])
  useEffect(() => {
    if (isOpen) {
      setNimi('')
      setEtunimi('')
      setSukunimi('')
      setIsActive(true)
      setIsAdmin(0)
      setMaakunnat(undefined)
      setKoko(0)
      reset()
    }
  }, [isOpen])
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      return () => {
        clearTimeout(handler)
      }
    }, [value, delay])
    return debouncedValue
  }
  const debouncedYtunnus = useDebounce(ytunnus, 500)

  useEffect(() => {
    if (debouncedYtunnus) {
      fetchYtunnus({ variables: { ytunnus: debouncedYtunnus } })
    }
  }, [debouncedYtunnus])
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={10}>
            <Steps activeStep={activeStep} colorScheme="brand.success">
              <Step label="Hae" key="Hae">
                <Box mt={5}>
                  <Label
                    text={intl.formatMessage({
                      id: 'email',
                      defaultMessage: 'Sähköposti',
                    })}
                  />
                  <Input type="text" id="email" disabled={true} value={email} />
                  <Label
                    text={intl.formatMessage({
                      id: 'etunimi',
                      defaultMessage: 'Etunimi',
                    })}
                  />
                  <Input
                    type="text"
                    id="etunimi"
                    value={etunimi}
                    onChange={e => setEtunimi(e.target.value)}
                  />
                  <Label
                    text={intl.formatMessage({
                      id: 'sukunimi',
                      defaultMessage: 'Sukunimi',
                    })}
                  />
                  <Input
                    type="text"
                    id="sukunimi"
                    value={sukunimi}
                    onChange={e => setSukunimi(e.target.value)}
                  />
                  <Label
                    text={intl.formatMessage({
                      id: 'ytunnus',
                      defaultMessage: 'y-tunnus',
                    })}
                  />
                  <Input
                    type="text"
                    id="ytunnus"
                    placeholder="1234567-8"
                    value={ytunnus}
                    onChange={e => setYtunnus(e.target.value)}
                  />
                </Box>{' '}
                <ModalFooter mt={5}>
                  <Button
                    aria-label="Seuraava"
                    disabled={
                      nimi.length > 0 && etunimi.length > 0 && sukunimi.length > 0 ? false : true
                    }
                    alignSelf="right"
                    onClick={async () => {
                      nextStep()
                    }}
                  >
                    seuraava
                  </Button>
                </ModalFooter>
              </Step>
              <Step label="Organisaatio" key="Luo organisaatio">
                <Stack p={4} spacing={4}>
                  <Heading as="h2" size="md">
                    <FormattedMessage id="create_organization" defaultMessage="Luo Organisaatio" />
                  </Heading>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'name',
                        defaultMessage: 'Nimi',
                      })}
                    />
                    <Input value={nimi} disabled={true} />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'maakunta',
                        defaultMessage: 'Maakunta',
                      })}
                    />

                    <MultiSelect
                      //@ts-ignore
                      isMulti={false}
                      allowSelectAll={false}
                      placeholder="Valitse&hellip;"
                      onChange={e => setMaakunnat(e.value)}
                      options={[
                        ...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] })),
                      ]}
                    />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'koko',
                        defaultMessage: 'Henkilöstömäärä',
                      })}
                    />

                    <NumberInput
                      w={24}
                      size="sm"
                      value={koko}
                      defaultValue={0}
                      min={0}
                      onChange={maxAnswersString => setKoko(parseInt(maxAnswersString))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                </Stack>
                <ModalFooter>
                  <HStack align="center">
                    <Button aria-label="Edellinen" onClick={() => prevStep()}>
                      Edellinen
                    </Button>
                    <Button
                      aria-label="Seuraava"
                      disabled={nimi && koko && maakunnat ? false : true}
                      onClick={() => nextStep()}
                    >
                      Seuraava
                    </Button>
                  </HStack>
                </ModalFooter>
              </Step>
              <Step label="Tallenna" key="Tallenna">
                <Stack p={4} spacing={4}>
                  <Heading as="h2" size="md">
                    Organisaatio
                  </Heading>
                  {nimi && (
                    <Box>
                      <Heading as="h3" size="md">
                        Nimi
                      </Heading>
                      <Text>{nimi}</Text>
                    </Box>
                  )}
                  {maakunnat && (
                    <Box>
                      <Heading as="h3" size="md">
                        Maakunta
                      </Heading>
                      <Text>{Maakunnat[maakunnat]}</Text>
                    </Box>
                  )}
                  {koko && (
                    <Box>
                      <Heading as="h3" size="md">
                        Koko
                      </Heading>
                      <Text>{koko}</Text>
                    </Box>
                  )}
                  {email && (
                    <Box>
                      <Heading as="h3" size="md">
                        Sähköposti
                      </Heading>
                      <Text>{email}</Text>
                    </Box>
                  )}
                </Stack>
                <ModalFooter>
                  <HStack align="center">
                    <Button aria-label="Edellinen" onClick={() => prevStep()}>
                      Edellinen
                    </Button>
                    <Button
                      aria-label="Seuraava"
                      onClick={() => {
                        sendCreateOrganization()
                        onClose()
                      }}
                    >
                      Tallenna
                    </Button>
                  </HStack>
                </ModalFooter>
              </Step>
            </Steps>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
