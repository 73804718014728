import { gql } from '@apollo/client'

export const ALL_USERS = gql`
  query users {
    users {
      email
      organisaatiot {
        nimi
        id
      }
    }
  }
`
