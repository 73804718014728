import { Flex, Button } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { languages } from '../constants'
interface LanguageButtonProps {
  text: string
  value: string
  language: string
  setLanguage: (language: string) => void
  borders: 'left' | 'right' | 'none'
}

const LanguageButton: React.FC<LanguageButtonProps> = ({
  text,
  value,
  language,
  setLanguage,
  borders,
}) => {
  const onClick = useCallback(
    e => {
      setLanguage(e.target.value)
    },
    [language]
  )
  return (
    <Button
      aria-label="Language Switcher"
      value={value}
      variant={`language-switcher-button-${borders}`}
      isActive={language === value}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

export function LangSwitcher(props) {
  const { fi, en, se } = languages
  const [language, setLanguage] = useState(fi)

  const languageButtons: { text: string; value: string; borders: 'left' | 'right' | 'none' }[] = [
    { text: 'Suomeksi', value: fi, borders: 'left' },
    { text: 'In English', value: en, borders: 'none' },
    { text: 'På Svenska', value: se, borders: 'right' },
  ]

  useEffect(() => {
    localStorage.setItem('language', language)
    props.setLanguage(language)
  }, [language])

  return (
    <Flex>
      {languageButtons.map(({ text, value, borders }, index) => (
        <LanguageButton
          key={`$language-button-${index}`}
          text={text}
          value={value}
          language={language}
          setLanguage={setLanguage}
          borders={borders}
        />
      ))}
    </Flex>
  )
}

export default LangSwitcher
