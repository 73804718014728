import { Button } from '@chakra-ui/react'
import firebase from 'firebase'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthState } from '../contexts/Firebase/Context'

const Logout = () => {
  const history = useHistory()
  const { user } = useAuthState()
  const logout = useCallback(() => {
    const out = async () => {
      await firebase.auth().signOut()
      localStorage.removeItem('organisaatio')
      localStorage.removeItem('loginToken')
      history.push('/login')
    }
    out()
  }, [history])

  return user ? (
    <Button
      aria-label="Logout"
      variant="ghost"
      colorScheme="brand.secondary"
      onClick={() => logout()}
    >
      Logout
    </Button>
  ) : (
    <Button
      aria-label="Login"
      variant="ghost"
      colorScheme="brand.secondary"
      onClick={() => history.push('/login')}
    >
      Login
    </Button>
  )
}

export default Logout
