import { createContext, useContext } from 'react'

export type OrganizationContextType = {
  // prettier-ignore
  organization: string,
  setOrganization: (Organization: string) => void
}

export const OrganizationContext = createContext<OrganizationContextType>({
  organization: '',
  setOrganization: organization => console.warn('no organization provided'),
})

export const useOrganization = () => useContext(OrganizationContext)
