//1.
import React, { useContext, useEffect, useState } from 'react'
import app from './firebase-config'

export type AuthontextType = {
  // prettier-ignore
  user: firebase.default.User | null,
}
//2.
export const AuthContext = React.createContext<AuthontextType>({ user: null })

//3.
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<firebase.default.User | null>(null)

  useEffect(() => {
    app.auth().onAuthStateChanged(auth => {
      if (!auth) {
        localStorage.removeItem('loginToken')
      }
      setUser(auth)
    })
  }, [])

  return <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuth: auth.user != null }
}
