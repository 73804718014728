//1. import the dependencies
import firebase from 'firebase'

//2. Initialize app with the config vars
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_ALFAME_API_KEY,
  authDomain: 'alfame-bi.firebaseapp.com',
  databaseURL: 'https://alfame-bi.firebaseio.com',
  projectId: 'alfame-bi',
  storageBucket: 'alfame-bi.appspot.com',
  messagingSenderId: '713268936052',
  appId: '1:713268936052:web:0871edbe12517b2c3c8021',
  measurementId: 'G-CQ050L0BH7',
})

//3. export it for use
export default app
