import { gql } from '@apollo/client'

export const VASTAUKSET = gql`
  query vastaukset($patteristoId: String!) {
    vastaus: vastaukset(id: $patteristoId) {
      index
      otsikko
      aliotsikko
      kysymys
      roles {
        name
      }
      data {
        role {
          id
          name
          parentRole {
            id
            name
          }
        }
        values {
          key
          count
        }
        average
        errorMargin
        roleCount
        comparation
      }
    }
  }
`
