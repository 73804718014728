import MultiSelect from './MultiSelect'
import Maakunnat from '../enums/Maakunnat'
import {
  Text,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  HStack,
} from '@chakra-ui/react'
import Select from 'react-select'

export function Filter(props) {
  return (
    <Popover closeOnBlur={false}>
      <PopoverTrigger>
        <Button aria-label="Suodata">Suodata</Button>
      </PopoverTrigger>
      <PopoverContent w="auto" ml="24px" p={2}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody display="flex" fontWeight="normal">
          <HStack spacing="24px" align="top">
            <Box w="200px">
              <Text>Maakunnat</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={true}
                allowSelectAll={true}
                placeholder="Valitse&hellip;"
                onChange={e => props.filter(e, 'maakunnat')}
                options={[...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] }))]}
              />
            </Box>

            <Box w="200px">
              <Text>Henkilöstömäärä</Text>
              <Select
                placeholder="Valitse&hellip;"
                onChange={e => props.filter(e, 'henkilöstömäärä')}
                options={[
                  { value: 0, label: '1-50' },
                  { value: 1, label: '51-100' },
                  { value: 2, label: '>100' },
                  { value: -1, label: 'kaikki' },
                ]}
              />
            </Box>

            <Box w="200px">
              <Text>Aktiivinen</Text>
              <Select
                placeholder="Valitse&hellip;"
                onChange={e => props.filter(e, 'aktiivinen')}
                options={[
                  { value: 1, label: 'Kyllä' },
                  { value: 0, label: 'Ei' },
                  { value: -1, label: 'Kaikki' },
                ]}
              />
            </Box>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default Filter
