/* eslint-disable indent */
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ErrorBar,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts'

import { colors } from '../theme/colors'
import '../theme/reportAndResultStyles.css'
import { useCallback, useState } from 'react'
const renderCustomizedLabel = props => {
  const { x, y, width, height, value, offset } = props

  return (
    //   x={x + 10}
    //   y={y + height - offset}
    //   height={height}
    //   width={width}
    //   textAnchor="start"
    //   fontSize={height * 0.8}
    //   fill={colors.brand.white}
    // ></text>
    <text
      x={x}
      y={y + height - offset}
      height={height}
      width={width}
      dy={-4}
      fontSize={height * 0.7}
      fill={colors.brand.white}
      textAnchor="start"
    >
      {value % 1 === 0 ? value : value.toFixed(2)}
    </text>
  )
}

const formatXAxis = (value: number) => {
  switch (value) {
    case 1:
      return '1: Täysin eri mieltä'
    case 2:
      return '2: Jokseenkin eri mieltä'
    case 3:
      return '3: Jokseenkin samaa mieltä'
    case 4:
      return '4: Täysin samaa mieltä'
    default:
      return ''
  }
}
export function ResultChart(props) {
  let propsData = props.data[0]
  propsData = props.data.map(d => {
    return d.data.map(e => {
      console.log(e)
      let findIndex = props.roles.findIndex(e1 => {
        return e1.name === e.role.name
      })
      return {
        ...e,
        role: e.role.name,
        id: e.role.id,
        fill: colors.chart[findIndex][e.comparation ? 1 : 0],
        vaName: `vertaisarvo ${e.role.name}`,
        khName: `keskihajonta ${e.role.name}`,
        value: e.average,
      }
    })
  })
  propsData = propsData[0]

  const barTooltipStyle = {
    outerWidth: 10,
    backgroundColor: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '0.5em',
    padding: '10px',
    fontFamily: 'helvetica, sans-serif',
    fontSize: '16px',
  }

  const [barTooltip, setBarTooltip] = useState('')

  const BarTooltip = useCallback(
    ({ active, payload }) => {
      if (!active || !barTooltip) return null
      for (const bar of payload) {
        return (
          <div className="barTooltip" style={barTooltipStyle}>
            <p style={{ color: bar.payload.fill }}>
              {bar.payload.comparation ? bar.payload.vaName : bar.payload.role}
            </p>
            <p>{bar.value.toFixed(2)}</p>
          </div>
        )
      }
      return null
    },
    [barTooltip]
  )

  return (
    <ResponsiveContainer>
      <BarChart data={propsData} layout="vertical" margin={{ left: 100, right: 200 }}>
        <XAxis
          domain={[0, 4]}
          allowDataOverflow={true}
          type="number"
          tickCount={5}
          tickFormatter={formatXAxis}
        />
        <YAxis type="category" dataKey="kysymys" tick={false} width={1} />
        <Tooltip
          content={<BarTooltip active={undefined} payload={undefined} />}
          cursor={false}
          position={{}}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        <ReferenceLine x={2.5} stroke="black" />
        <CartesianGrid strokeDasharray="1 1" />
        <Bar
          name="name"
          dataKey="average"
          onMouseOver={() => setBarTooltip('true')}
          onMouseLeave={() => setBarTooltip('')}
        >
          <LabelList
            dataKey="average"
            position="insideLeft"
            name="vaName"
            content={renderCustomizedLabel}
          />
          <ErrorBar name="khName" dataKey="errorMargin" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}
