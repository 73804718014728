import { createContext, useContext } from 'react'

export type CollectionContextType = {
  // prettier-ignore
  collection: string,
  setCollection: (Collection: string) => void
}

export const CollectionContext = createContext<CollectionContextType>({
  collection: '',
  setCollection: collection => console.warn('no collection provided'),
})

export const useCollection = () => useContext(CollectionContext)
