import { Text } from '@chakra-ui/react'

export function Label(props) {
  return (
    <Text mb={1} fontSize="xs" fontWeight="semibold" textTransform="uppercase" opacity=".75">
      {props.text}
    </Text>
  )
}

export default Label
