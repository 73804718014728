import { Link } from 'react-router-dom'

const NotFound = () => (
  <div>
    <h1>Error 404</h1>
    <h2>Sivua ei löytynyt! Sidan hittas inte! Page not found!</h2>
    <Link to="/">Palaa alkuun - Gå hem - Go Home</Link>
  </div>
)

export default NotFound
