import {
  Box,
  Heading,
  HStack,
  IconButton,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Wrap,
} from '@chakra-ui/react'
import fi from 'date-fns/locale/fi'
import { ReactElement, useEffect, useState } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import Maakunnat from '../enums/Maakunnat'

import { gql, useMutation, useQuery } from '@apollo/client'
import { EditIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import 'react-datepicker/dist/react-datepicker.css'
import AdminPanelOrganizationSorter from '../components/AdminPanelOrganizationSorter'
import Pagination from '../components/AdminPanelPagination'
import { CreateOrganizationSteps } from '../components/CreateOrganizationSteps'
import { CreateUserModal } from '../components/CreateUserModal'
import Filter from '../components/Filter'
import { UpdateOrganizationModal } from '../components/UpdateOrganizationModal'
import { ADD_USER_TO_ORGANIZATION } from '../mutations/add_user_to_organization'
import { CREATE_ORGANIZATION } from '../mutations/create_organization'
import { CREATE_USER } from '../mutations/create_user'
import { REMOVE_USER_FROM_ORGANIZATION } from '../mutations/remove_user_from_organization'
import { UPDATE_ORGANIZATION } from '../mutations/update_organization'
import { ME } from '../queries/me'
import { ALL_ORGANIZATIONS } from '../queries/organizations'
import AdminCompareWithFilters from './AdminCompareWithFilters'

registerLocale('fi', fi)
setDefaultLocale('fi')

function AdminCreatePanel(params): ReactElement {
  const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER)
  const [createOrganization] = useMutation(CREATE_ORGANIZATION)
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)
  const [removeUserFromOrganizationMutation] = useMutation(REMOVE_USER_FROM_ORGANIZATION)
  const [addUserToOrganization] = useMutation(ADD_USER_TO_ORGANIZATION)
  const { isOpen, onClose, onOpen } = useDisclosure()
  //const [updateOrganizationSorting] = useQuery(ALL_ORGANIZATIONS)

  const { data: userData, refetch: userRefetch } = useQuery(
    gql`
      query users {
        users {
          id
          email
          etunimi
          sukunimi
          organisaatiot {
            id
            nimi
          }
          isAdmin
          active
        }
      }
    `,
    { fetchPolicy: 'cache-and-network', pollInterval: 60000 }
  )

  const { data: meData } = useQuery(ME)

  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()
  const [nimi, setNimi] = useState('')
  const [koko, setKoko] = useState(0)
  const [organisaatiot, setOrganisaatiot] = useState<any[]>([])
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(0)
  const [me, setMe] = useState({ isAdmin: 0 })
  const [isActive, setIsActive] = useState(true)
  const [etunimi, setEtunimi] = useState('')
  const [sukunimi, setSukunimi] = useState('')
  const [sorting, setSorting] = useState({ sorter: 'nimi', direction: 'ASC' })
  const [currentPage, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(2)
  //Filters
  const [henkilostomaara, setHenkilostomaara] = useState(-1)
  const [aktiivinen, setAktiivinen] = useState(-1)
  const [maakunnat, setMaakunnat] = useState<Maakunnat>()

  const { data, refetch } = useQuery(ALL_ORGANIZATIONS, {
    variables: {
      sorting: sorting.sorter,
      direction: sorting.direction,
      currentPage: currentPage,
      koko: henkilostomaara,
      maakunnat,
      active: aktiivinen,
    },
  })

  const [updateOrganizationId, setUpdateOrganizationId] = useState({ id: null, type: '' })

  const handlePrevPage = (prevPage: number) => {
    setPage(prevPage => prevPage - 1)
  }

  const handleNextPage = (nextPage: number) => {
    setPage(nextPage => nextPage + 1)
  }

  const sendCreateUser = async () => {
    try {
      await createUser({
        variables: {
          //@ts-ignore
          email,
          isAdmin,
          etunimi,
          sukunimi,
          isActive,
        },
      })
      setEmail('')
      setEtunimi('')
      setSukunimi('')
      toast({
        title: intl.formatMessage({
          id: 'create_user_success',
          defaultMessage: 'Käyttäjä luotu/päivitetty!',
        }),
        status: 'success',
        duration: 5000,
      })
    } catch (e) {
      toast({
        title: intl.formatMessage({
          id: 'create_user_fail',
          defaultMessage: 'Jokin meni vikaan.',
        }),
        status: 'error',
        duration: 5000,
      })
    }
  }
  useEffect(() => {
    if (data) {
      setOrganisaatiot([...data.organizations.organizations])
      setTotalPages(data.organizations.pageInfo.totalPages || 1)
    }
  }, [data])

  const getSortedOrganizations = () => {
    const sortedOrganizations = [...organisaatiot]

    return sortedOrganizations.sort((a, b) => {
      if (a.nimi.toUpperCase() < b.nimi.toUpperCase()) return -1
      if (a.nimi.toUpperCase > b.nimi.toUpperCase()) return 1
      return 0
    })
  }

  const handleFilterMultiSelect = (e, type) => {
    if (type == 'maakunnat') setMaakunnat(e.map(item => item.value))
    if (type == 'henkilöstömäärä') setHenkilostomaara(e.value)
    if (type == 'aktiivinen') setAktiivinen(e.value)
  }

  return (
    <Box textAlign="left">
      <Heading as="h1" size="xl" mb={4}>
        <FormattedMessage id="adminPanel" defaultMessage="Admin paneeli" />
      </Heading>

      <Tabs colorScheme="brand.primary" size="md" variant="solid-rounded">
        <UpdateOrganizationModal
          updateOrganizationId={updateOrganizationId}
          fetchCurrentOrganization={() => {}}
          organisaatiot={organisaatiot}
          isOpen={isOpen}
          onClose={onClose}
          userData={userData}
          updateOrganization={updateOrganization}
          refetch={refetch}
          createUser={createUser}
          userRefetch={userRefetch}
          removeUserFromOrganizationMutation={removeUserFromOrganizationMutation}
          addUserToOrganization={addUserToOrganization}
        />
        <TabList ml={4}>
          <Tab
            onClick={() => {
              refetch()
            }}
          >
            {intl.formatMessage({
              id: 'manage_organization',
              defaultMessage: 'Organisaation Hallinta',
            })}
          </Tab>
          <Tab
            isDisabled={meData?.me.isAdmin !== 2}
            onClick={() => {
              userRefetch()
            }}
          >
            {intl.formatMessage({
              id: 'create_user',
              defaultMessage: 'Käyttäjänhallinta',
            })}
          </Tab>
          <Tab>
            {intl.formatMessage({
              id: 'compareFilters',
              defaultMessage: 'Vertaa filttereillä',
            })}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <HStack mb={4} spacing={4}>
                <Heading flex="1" as="h2" size="md">
                  <HStack spacing="48px">
                    <Box>
                      <FormattedMessage id="manage_organization" defaultMessage="Organisaatiot" />
                    </Box>
                    <Box>
                      <Filter filter={handleFilterMultiSelect} />
                    </Box>
                  </HStack>
                </Heading>
                <Box>
                  {meData.me.isAdmin === 2 && <CreateOrganizationSteps refetch={refetch} />}
                </Box>
              </HStack>
              <Wrap spacing={2}>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBNimi="indeksi"
                      >
                        Indeksi
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="nimi"
                      >
                        Nimi
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="maakunta"
                      >
                        Maakunta
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="koko"
                      >
                        Henkilömäärä
                      </AdminPanelOrganizationSorter>
                      <Th>Pääkäyttäjä</Th>
                      <Th>Patteristot</Th>
                      <Th>Aktiivinen</Th>
                      <Th>Editoi</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {organisaatiot.map((e, i) => {
                      return (
                        <Tr key={e.id}>
                          <Td>{i + 1 + 30 * currentPage - 30}</Td>
                          <Td>
                            <a
                              href="#"
                              onClick={a => {
                                a.preventDefault()
                                window.localStorage.setItem('organisaatio', e.id)
                                history.push('/admin')
                              }}
                            >
                              {e.nimi} <ExternalLinkIcon />
                            </a>
                          </Td>
                          <Td>{Maakunnat[e.maakunta]}</Td>
                          <Td isNumeric>{e.koko}</Td>
                          {/* <Td>
                            {e.users.map(a => (
                              <p>{a.email}</p>
                            ))}
                            </Td> */}
                          <Td>
                            {e.users.length > 0
                              ? `${e.users[0].email}${' '}
                              ${e.users.length > 1 ? `(${e.users.length})` : ``}`
                              : ''}
                          </Td>
                          <Td textAlign="center">
                            {e.patteristot.length}
                            {e.patteristot.length > 0 &&
                              ` (${new Date(
                                Math.max(
                                  ...e.patteristot.map(element => {
                                    return new Date(element.created_at)
                                  })
                                )
                              ).toLocaleDateString()})`}
                          </Td>
                          <Td>{e.active ? 'Kyllä' : 'Ei'}</Td>
                          <Td>
                            <IconButton
                              aria-label="Edit"
                              onClick={() => {
                                setUpdateOrganizationId({ id: e.id, type: 'organization' })
                                onOpen()
                              }}
                              icon={<EditIcon />}
                            />
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBNimi="indeksi"
                      >
                        Indeksi
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="nimi"
                      >
                        Nimi
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="maakunta"
                      >
                        Maakunta
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="koko"
                      >
                        Henkilömäärä
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="users"
                      >
                        Pääkäyttäjä
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBName="patteristot"
                      >
                        Patteristot
                      </AdminPanelOrganizationSorter>
                      <AdminPanelOrganizationSorter
                        sorting={sorting}
                        setSorting={setSorting}
                        DBname="active"
                      >
                        Aktiivinen
                      </AdminPanelOrganizationSorter>
                      <Th>Editoi</Th>
                    </Tr>
                  </Tfoot>
                </Table>
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />
                {/* <Box borderWidth="1px" borderRadius="lg" fontSize="sm" minW="500px" maxW="500px">
                  <Stack p={4} spacing={4}>
                    <Heading as="h3" size="sm">
                      <FormattedMessage id="organization_info" defaultMessage="Tiedot" />
                    </Heading>
                    <Stack p={0} spacing={4}>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'name',
                            defaultMessage: 'Nimi',
                          })}
                        />
                        <Input value={updateNimi} onChange={e => setUpdateNimi(e.target.value)} />
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'maakunta',
                            defaultMessage: 'Maakunta',
                          })}
                        />

                        <MultiSelect
                          //@ts-ignore
                          isMulti={false}
                          value={{
                            label: Maakunnat[updateMaakunnat],
                            value: updateMaakunnat,
                          }}
                          placeholder="Valitse&hellip;"
                          onChange={e => setUpdateMaakunnat(e.value)}
                          options={[
                            ...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] })),
                          ]}
                        />
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'omistuspohja',
                            defaultMessage: 'Omistuspohja',
                          })}
                        />

                        <MultiSelect
                          //@ts-ignore
                          isMulti={false}
                          value={{
                            label: OmistusPohja[updateOmistuspohja],
                            value: updateOmistuspohja,
                          }}
                          placeholder="Valitse&hellip;"
                          onChange={e => setupdateOmistuspohja(e.value)}
                          options={[
                            ...Object.keys(OmistusPohja).map(e => ({
                              value: e,
                              label: OmistusPohja[e],
                            })),
                          ]}
                        />
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'koko',
                            defaultMessage: 'Henkilöstömäärä',
                          })}
                        />

                        <NumberInput
                          w={24}
                          size="sm"
                          value={updateKoko}
                          min={0}
                          onChange={maxAnswersString => setUpdateKoko(parseInt(maxAnswersString))}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'organization_active',
                            defaultMessage: 'Aktiivinen',
                          })}
                        />
                        <Switch
                          size="lg"
                          isChecked={updateAktiivinen}
                          onChange={e => setUpdateAktiivinen(e.target.checked)}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Wrap spacing={4} px={4} py={3} borderTopWidth="1px">
                    <Button variant="primary" onClick={sendUpdateOrganization}>
                      {<FormattedMessage id="save" defaultMessage="Tallenna" />}
                    </Button>
                  </Wrap>
                </Box> */}
                {/* <Box borderWidth="1px" borderRadius="lg" fontSize="sm" minW="500px" maxW="500px">
                  <Stack p={4} spacing={4}>
                    <Heading as="h3" size="sm">
                      <FormattedMessage id="organization_users" defaultMessage="Käyttäjät" />
                    </Heading>
                    <Box>
                      <Label
                        text={intl.formatMessage({
                          id: 'email',
                          defaultMessage: 'Sähköposti',
                        })}
                      />
                      <Flex>
                        <Input onChange={e => setAddEmail(e.target.value)} flex="1 1 auto" mr="1" />
                        <Button variant="primary" onClick={sendAddUserToOrganization}>
                          {<FormattedMessage id="addUser" defaultMessage="Lisää" />}
                        </Button>
                      </Flex>
                    </Box>
                    <Box>
                      <List spacing={3}>
                        {organizationUsers.map((user: any) => (
                          <ListItem key={user.id} fontSize="sm">
                            {`${user.etunimi} ${user.sukunimi} `}
                            <Link href={`mailto:${user.email}`}>
                              {user.email}
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </Stack>
                </Box> */}
              </Wrap>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box float="right">
              <CreateUserModal refetch={userRefetch} />
            </Box>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Sähköposti</Th>
                  <Th>Etunimi</Th>
                  <Th>Sukunimi</Th>
                  <Th>Organisaatio</Th>
                  <Th>Admin</Th>
                  <Th>Aktiivinen</Th>
                  <Th>Editoi</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userData &&
                  [...userData?.users]
                    .sort((a, b) => {
                      if (a.active != b.active) {
                        return a.active ? -1 : 1
                      }
                      if (a.email.toUpperCase() < b.email.toUpperCase()) return -1
                      if (a.email.toUpperCase() > b.email.toUpperCase()) return 1
                      return 0
                    })
                    .map(e => {
                      return (
                        <Tr key={e.id}>
                          <Td>{e.email}</Td>
                          <Td>{e.etunimi}</Td>
                          <Td>{e.sukunimi}</Td>
                          <Td>
                            {e.organisaatiot.length > 0
                              ? e.organisaatiot.map(org => org.nimi).join(', ')
                              : '-'}
                          </Td>
                          <Td>{['Ei', 'Admin', 'Superadmin'][e.isAdmin || 0]}</Td>
                          <Td>{e.active ? 'Kyllä' : 'Ei'}</Td>

                          <Td>
                            <IconButton
                              aria-label="Edit"
                              onClick={() => {
                                setUpdateOrganizationId({ id: e.id, type: 'user' })
                                onOpen()
                              }}
                              icon={<EditIcon />}
                            />
                          </Td>
                        </Tr>
                      )
                    })}
              </Tbody>
            </Table>
          </TabPanel>
          <TabPanel>
            <AdminCompareWithFilters />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default AdminCreatePanel
