import { gql } from '@apollo/client'

export const INDIVIDUAL_ANSWERS = gql`
  query getIndividualAnswers($patteristoId: String!, $filters: FilterInput) {
    vastaus: report(id: $patteristoId, filters: $filters) {
      index
      otsikko
      aliotsikko
      kysymys
      roles {
        name
      }
      data {
        role {
          id
          name
          parentRole {
            id
            name
          }
        }
        values {
          key
          count
        }
        average
        errorMargin
        roleCount
        comparation
      }
    }
  }
`
