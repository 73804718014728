import { Box, Heading, useToast } from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase'
import { useLazyQuery } from '@apollo/client'
import { ME } from '../queries/me'
import { useHistory } from 'react-router-dom'
import { useAuthState } from '../contexts/Firebase/Context'
import { useIntl } from 'react-intl'
import { RegisterModal } from '../components/RegisterModal'

const createAccountBool = process.env.REACT_APP_CREATE_ACCOUNT_BOOL || false

// firebase.initializeApp(config)

const uiConfig = {
  signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
  signInSuccessUrl: '/login',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    },
  ],
}
// Initialize Firebase
function AdminLogin(): ReactElement {
  const [isSignedIn, setIsSignedIn] = useState(false) // Local signed-in state.
  const [token, setToken] = useState<string | null>()
  const [fetchMe, { data, loading }] = useLazyQuery(ME, {
    onCompleted: () => {
      if (data?.me?.organisaatiot.length > 0 && user) {
        if (data.me.organisaatiot[0].active) {
          localStorage.setItem('organisaatio', data.me.organisaatiot[0].id)
          history.push('/admin')
        } else {
          toast({
            title: intl.formatMessage({
              id: 'organization_inactive',
              defaultMessage: 'Käyttäjän organisaatio ei ole aktiivinen!',
            }),
            status: 'error',
            duration: 5000,
          })
        }
      } else if (data === undefined && user?.email && !loading && createAccountBool) {
        setOpen(true)
      }

      if (data.me) {
        history.push('/admin')
      }
    },
    onError: error => {
      if (data === undefined && user?.email && !loading && createAccountBool) {
        setOpen(true)
      }
    },
  })
  const { user } = useAuthState()
  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const getToken = async () => {
      if (user) {
        localStorage.setItem('loginToken', await user.getIdToken())

        if (localStorage.getItem('loginToken')) {
          fetchMe()
        }
      }
    }
    getToken()
    // eslint-disable-next-line
  }, [user])

  return (
    <Box textAlign="center" w="100%">
      <Heading>Kirjaudu / Rekisteröidy</Heading>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <RegisterModal email={user?.email} open={open} fetchMe={fetchMe} />
    </Box>
  )
}

export default AdminLogin
