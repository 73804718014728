import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Step, Steps, useSteps } from 'chakra-ui-steps'
import { useEffect, useState } from 'react'
import { ALL_USERS } from '../queries/all_users'

import MultiSelect from './MultiSelect'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { FormattedMessage, useIntl } from 'react-intl'
import { Label } from './Label'
import Maakunnat from '../enums/Maakunnat'
import { OmistusPohja } from '../enums/Omistuspohja'
import { CREATE_ORGANIZATION } from '../mutations/create_organization'
import { CREATE_USER } from '../mutations/create_user'
import { ADD_USER_TO_ORGANIZATION } from '../mutations/add_user_to_organization'
import { FaPlus } from 'react-icons/fa'
import { colors } from '../theme/colors'
const content = <Flex py={4}>asd</Flex>

export const CreateOrganizationSteps = props => {
  const refetch = props.refetch
  const { data: userData, refetch: userRefetch } = useQuery(ALL_USERS)
  const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER)
  const [createOrganization] = useMutation(CREATE_ORGANIZATION)
  const [addUserToOrganization] = useMutation(ADD_USER_TO_ORGANIZATION)
  const [users, setUsers] = useState<any>([])
  const intl = useIntl()

  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  })
  const [newUser, setNewUser] = useState(0)
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(0)
  const [isActive, setIsActive] = useState(true)
  const [etunimi, setEtunimi] = useState('')
  const [sukunimi, setSukunimi] = useState('')

  const [maakunnat, setMaakunnat] = useState<Maakunnat>()
  const [omistuspohja, setOmistuspohja] = useState<OmistusPohja>()
  const [nimi, setNimi] = useState('')
  const [koko, setKoko] = useState(0)

  const toast = useToast()

  const sendCreateUser = async () => {
    try {
      await createUser({
        variables: {
          //@ts-ignore
          email,
          isAdmin,
          etunimi,
          sukunimi,
          isActive,
        },
      })
    } catch (e) {}
  }

  const sendCreateOrganization = async () => {
    try {
      const organization = await createOrganization({
        variables: {
          nimi,
          maakunta: maakunnat,
          omistuspohja,
          koko,
        },
      })
      if (newUser) {
        await sendCreateUser()
      }
      await addUserToOrganization({
        variables: {
          email: email,
          organizationId: organization.data.createOrganization.id,
        },
      })
      refetch()

      toast({
        title: intl.formatMessage({
          id: 'create_organiaztion_success',
          defaultMessage: 'Organisation luonti onnistui!',
        }),
        status: 'success',
        duration: 5000,
      })
    } catch (e) {
      toast({
        title: intl.formatMessage({
          id: 'create_organization_error',
          defaultMessage: 'Jokin meni vikaan.',
        }),
        status: 'error',
        duration: 5000,
      })
    }
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    if (userData?.users) {
      setUsers(userData.users)
    }
  }, [userData])
  return (
    <>
      <IconButton
        aria-label="Add"
        variant="primary-round"
        onClick={() => {
          setEmail('')
          setNimi('')
          setEtunimi('')
          setSukunimi('')
          setIsActive(true)
          setIsAdmin(0)
          setMaakunnat(undefined)
          setOmistuspohja(undefined)
          setKoko(0)
          reset()

          onOpen()
        }}
        icon={<FaPlus color={colors.brand.white} />}
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={10}>
            <Steps activeStep={activeStep} colorScheme="brand.success">
              <Step label="Käyttäjä" key="Luo Käyttäjä">
                <Box mt={5}>
                  <Creatable
                    options={users.map(e => ({ value: e.email, label: e.email }))}
                    pl={5}
                    onChange={e => {
                      setEmail(e.value)
                      setNewUser(0)
                      setIsAdmin(0)
                      setIsActive(true)
                      setEtunimi('')
                      setSukunimi('')
                    }}
                    onCreateOption={e => {
                      setNewUser(1)
                      setEmail(e)
                    }}
                  />
                  {newUser === 1 && (
                    <Stack p={4} spacing={4}>
                      <Heading as="h2" size="md">
                        <FormattedMessage
                          id="create_user"
                          defaultMessage="Valitse / luo Käyttäjä"
                        />
                      </Heading>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'email',
                            defaultMessage: 'Sähköposti',
                          })}
                        />
                        <Input
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          type="email"
                        />
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'firstname',
                            defaultMessage: 'Etunimi',
                          })}
                        />
                        <Input value={etunimi} onChange={e => setEtunimi(e.target.value)} />
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'surname',
                            defaultMessage: 'Sukunimi',
                          })}
                        />
                        <Input value={sukunimi} onChange={e => setSukunimi(e.target.value)} />
                      </Box>
                      <Box>
                        <Label
                          text={intl.formatMessage({
                            id: 'isActive',
                            defaultMessage: 'Aktiivinen?',
                          })}
                        />

                        <Select
                          w={48}
                          onChange={e => setIsActive(e.value)}
                          defaultValue={{ value: true, label: 'Kyllä' }}
                          //@ts-ignore
                          options={[
                            { value: false, label: 'Ei' },
                            { value: true, label: 'Kyllä' },
                          ]}
                        />
                      </Box>
                    </Stack>
                  )}
                </Box>
                <ModalFooter mt={5}>
                  <Button
                    aria-label="Seuraava"
                    disabled={email ? false : true}
                    alignSelf="right"
                    onClick={() => nextStep()}
                  >
                    Seuraava
                  </Button>
                </ModalFooter>
              </Step>
              <Step label="Organisaatio" key="Luo organisaatio">
                <Stack p={4} spacing={4}>
                  <Heading as="h2" size="md">
                    <FormattedMessage id="create_organization" defaultMessage="Luo Organisaatio" />
                  </Heading>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'name',
                        defaultMessage: 'Nimi',
                      })}
                    />
                    <Input value={nimi} onChange={e => setNimi(e.target.value)} />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'maakunta',
                        defaultMessage: 'Maakunta',
                      })}
                    />

                    <MultiSelect
                      //@ts-ignore
                      isMulti={false}
                      placeholder="Valitse&hellip;"
                      onChange={e => setMaakunnat(e.value)}
                      options={[
                        ...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] })),
                      ]}
                    />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'omistuspohja',
                        defaultMessage: 'Omistuspohja',
                      })}
                    />

                    <MultiSelect
                      //@ts-ignore
                      isMulti={false}
                      placeholder="Valitse&hellip;"
                      onChange={e => setOmistuspohja(e.value)}
                      options={[
                        ...Object.keys(OmistusPohja).map(e => ({
                          value: e,
                          label: OmistusPohja[e],
                        })),
                      ]}
                    />
                  </Box>
                  <Box>
                    <Label
                      text={intl.formatMessage({
                        id: 'koko',
                        defaultMessage: 'Henkilöstömäärä',
                      })}
                    />

                    <NumberInput
                      w={24}
                      size="sm"
                      value={koko}
                      defaultValue={0}
                      min={0}
                      onChange={maxAnswersString => setKoko(parseInt(maxAnswersString))}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Box>
                </Stack>
                <ModalFooter>
                  <HStack align="center">
                    <Button aria-label="Edellinen" onClick={() => prevStep()}>
                      Edellinen
                    </Button>
                    <Button
                      aria-label="Seuraava"
                      disabled={nimi ? false : true}
                      onClick={() => nextStep()}
                    >
                      Seuraava
                    </Button>
                  </HStack>
                </ModalFooter>
              </Step>
              <Step label="Tallenna" key="Tallenna">
                <Stack p={4} spacing={4}>
                  <Heading as="h2" size="md">
                    Organisaatio
                  </Heading>
                  {nimi && (
                    <Box>
                      <Heading as="h3" size="md">
                        Nimi
                      </Heading>
                      <Text>{nimi}</Text>
                    </Box>
                  )}
                  {maakunnat && (
                    <Box>
                      <Heading as="h3" size="md">
                        Maakunta
                      </Heading>
                      <Text>{Maakunnat[maakunnat]}</Text>
                    </Box>
                  )}
                  {omistuspohja && (
                    <Box>
                      <Heading as="h3" size="md">
                        Omistuspohja
                      </Heading>
                      <Text>{OmistusPohja[omistuspohja]}</Text>
                    </Box>
                  )}
                  {koko && (
                    <Box>
                      <Heading as="h3" size="md">
                        Koko
                      </Heading>
                      <Text>{koko}</Text>
                    </Box>
                  )}
                  {email && (
                    <Box>
                      <Heading as="h3" size="md">
                        Sähköposti
                      </Heading>
                      <Text>{email}</Text>
                    </Box>
                  )}
                </Stack>
                <ModalFooter>
                  <HStack align="center">
                    <Button aria-label="Edellinen" onClick={() => prevStep()}>
                      Edellinen
                    </Button>
                    <Button
                      aria-label="Seuraava"
                      onClick={() => {
                        sendCreateOrganization()
                        onClose()
                      }}
                    >
                      Tallenna
                    </Button>
                  </HStack>
                </ModalFooter>
              </Step>
            </Steps>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
