import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import App from './App'
import theme from './theme'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { AuthProvider } from './contexts/Firebase/Context'
import { setContext } from '@apollo/client/link/context'
require('dotenv').config()

const apiUri =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PRODUCTION_API_URI
    : process.env.REACT_APP_DEVELOPMENT_API_URI
const authMiddleware = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: localStorage.getItem('loginToken') || null,
  },
}))

const httpLink = new HttpLink({ uri: apiUri })
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authMiddleware.concat(httpLink),
})

ReactDOM.render(
  <AuthProvider>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </React.StrictMode>
    </ApolloProvider>
  </AuthProvider>,
  document.getElementById('root')
)
