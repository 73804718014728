import { gql } from '@apollo/client'

export const CREATE_SURVEY = gql`
  mutation createSurvey(
    $collectionId: String!
    $organizationId: String!
    $deadline: DateTime!
    $maxVastaukset: Float!
    $example: Boolean!
    $roles: [IdInput!]!
  ) {
    createPatteristo(
      data: {
        collectionId: $collectionId
        organizationId: $organizationId
        deadline: $deadline
        maxVastaukset: $maxVastaukset
        example: $example
        roles: $roles
      }
    ) {
      id
    }
  }
`
