import { gql } from '@apollo/client'

export const ALL_COLLECTIONS = gql`
  query collections {
    collections {
      id
      nimi
    }
  }
`
