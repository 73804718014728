import { ReactElement, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  ChakraProvider,
  theme,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Flex,
} from '@chakra-ui/react'

export function PasswordInput({ setPassword, handleKeyDown }): ReactElement {
  const [show, setShow] = useState<boolean>(false)
  const handleClick = () => setShow(!show)

  const intl = useIntl()

  const textShow = intl.formatMessage({
    id: 'show',
    defaultMessage: 'Näytä',
  })

  const textHide = intl.formatMessage({
    id: 'hide',
    defaultMessage: 'Piilota',
  })

  return (
    <ChakraProvider theme={theme}>
      <Flex maxW="600px" w="100%">
        <InputGroup size="md">
          <Input
            onChange={event => setPassword(event.target.value)}
            onKeyDown={handleKeyDown}
            pr="4.5rem"
            type={show ? 'text' : 'password'}
            placeholder={intl.formatMessage({
              id: 'enterPassword',
              defaultMessage: 'Anna kyselyn salasana',
            })}
          />
          <InputRightElement width="4.5rem">
            <Button aria-label="Show" h="1.75rem" size="sm" onClick={handleClick}>
              {show ? textHide : textShow}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Flex>
    </ChakraProvider>
  )
}

export default PasswordInput
