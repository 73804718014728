import { gql } from '@apollo/client'
export interface RolesProps {
  id: string
  name: string
}
export interface RolesDataProps {
  id: string
  name: string
  subRoles: [RolesProps]
}

export const ROLES_BY_PATTERISTO_ID = gql`
  query rolesByPatteristoId($id: String!) {
    rolesByPatteristoId(id: $id) {
      id
      name
      subRoles {
        id
        name
      }
    }
  }
`
