import { theme as baseTheme } from '@chakra-ui/react'

import { colors } from '../colors'

const primaryButtonColors = {
  color: colors.brand.white,
  bg: colors.brand.primary[500],
  _hover: { bg: colors.brand.primary[600] },
  _active: { bg: colors.brand.primary[700] },
}

const primaryLigthButtonColors = {
  backgroundColor: colors.brand.primary[400],
  _hover: { bg: colors.brand.gray[200] },
  _active: { bg: colors.brand.gray[300] },
}

const languageSwitcherButtonStyles = {
  color: colors.brand.black,
  bg: baseTheme.colors.white,
  borderColor: 'none',
  borderWidth: '1px',
  borderRadius: 'none',
  px: 5,
  py: 6,
  fontSize: 'md',
  boxShadow: 'md',
  _active: {
    color: colors.brand.white,
    bg: colors.brand.primary[500],
    borderColor: colors.brand.primary[600],
  },
  _hover: {},
  _focus: {
    outline: 0,
    boxShadow: 'md',
  },
}

const button = {
  defaultProps: {
    colorScheme: 'brand.secondary',
  },
  baseStyle: {
    color: colors.brand.black,
  },
  variants: {
    primary: {
      ...primaryButtonColors,
    },
    'primary-round': {
      ...primaryLigthButtonColors,
      borderRadius: 'full',
    },
    'language-switcher-button-left': {
      borderLeftRadius: 'lg',
      ...languageSwitcherButtonStyles,
    },
    'language-switcher-button-none': {
      ...languageSwitcherButtonStyles,
    },
    'language-switcher-button-right': {
      borderRightRadius: 'lg',
      ...languageSwitcherButtonStyles,
    },
    secondary: {
      bg: colors.brand.secondary[500],
      color: colors.brand.white,
      _hover: { bg: colors.brand.secondary[600] },
      _active: { bg: colors.brand.secondary[700] },
    },
  },
}

export default button
