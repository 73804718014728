import { useLazyQuery, useQuery } from '@apollo/client'
import { Box, Button, Heading, Spinner, Stack, Text, useToast } from '@chakra-ui/react'
import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ChartLegend } from '../components/ChartLegend'
import MultiSelect from '../components/MultiSelect'
import PowerPointGeneratorButton from '../components/PowerPointGenerator'
import { ResultChart } from '../components/ResultChart'
import { useOrganization } from '../contexts/OrganizationContext'
import Maakunnat from '../enums/Maakunnat'
import { AnswerEntity } from '../models/AnswerEntity'
import '../theme/reportAndResultStyles.css'
import { ALL_COLLECTIONS } from '../queries/all_collections'
import { COMPARE_FILTERS_ANSWERS } from '../queries/compare_filters_answers'

function AdminCompareWithFilters(): ReactElement {
  const { organization, setOrganization } = useOrganization()

  const history = useHistory()

  const collections = useQuery(ALL_COLLECTIONS)

  const [collection, setCollection] = useState()
  const [kyselyt, setKyselyt] = useState([])
  const params = useParams<Record<string, string>>()
  const patteristo = params.id

  const [maakunnat, setMaakunnat] = useState(Object.keys(Maakunnat))
  const [koko, setKoko] = useState(0)

  const [maakunnat2, setMaakunnat2] = useState(Object.keys(Maakunnat))
  const [koko2, setKoko2] = useState(0)
  const [filters, setFilters] = useState(null)
  const [filters2, setFilters2] = useState(null)
  const [fetch, answerData] = useLazyQuery(COMPARE_FILTERS_ANSWERS, {
    variables: { collectionId: collection, filters1: filters, filters2: filters2 },
  })
  const [vastaus, setVastaukset] = useState<AnswerEntity | undefined>()
  const toast = useToast()

  useEffect(() => {
    if (filters && answerData.data) {
      setVastaukset(answerData.data.compare[0])
      if (!answerData.data.compare.some((e: AnswerEntity) => e.data.some(e2 => e2.roleCount > 0))) {
        toast({
          title: 'Verrokkiryhmässä on liian vähän vastauksia!',
          status: 'warning',
          position: 'top',
          duration: 10000,
          isClosable: true,
        })
      }
    }
  }, [filters, answerData.data])

  if (answerData.loading) {
    return (
      <Box textAlign="center">
        <Spinner size="xl" />
      </Box>
    )
  }
  const compareWithCustomGroup = () => {
    //@ts-ignore
    setFilters({ koko, maakunta: [...maakunnat.map(a => a)] })
    fetch({ variables: { collectionId: collection, filters1: filters, filters2: filters2 } })
  }

  const compareWithOwnSurveys = () => {
    //@ts-ignore
    setFilters({ koko, maakunta: [...maakunnat.map(a => a)] })
    //@ts-ignore
    setFilters2({ koko: koko2, maakunta: [...maakunnat2.map(a => a)] })
    const f1 = { koko, maakunta: [...maakunnat.map(a => a)] }
    const f2 = { koko: koko2, maakunta: [...maakunnat2.map(a => a)] }
    //@ts-ignore
    fetch({ variables: { collectionId: collection, filters1: f1, filters2: f2 } })
  }

  return (
    <Box textAlign="left">
      <Heading as="h1" mb={4}>
        Vastaukset
      </Heading>
      <Box fontSize="md" pb={14}>
        <Box className="no-print">
          <Box mt={4} mb={8}>
            <Button
              aria-label="Tulosta raportti"
              display="inline-block"
              mr={4}
              variant="primary"
              onClick={window.print}
            >
              Tulosta raportti
            </Button>
          </Box>
          <Box mt={4} mb={8}>
            {answerData.data && <PowerPointGeneratorButton data={answerData.data.compare} />}
          </Box>
          <Heading as="h2" size="sm" mb={2}>
            Valitse vertailuryhmä
          </Heading>
          <Stack isInline align="flex-end">
            <div style={{ width: '200px' }}>
              <Text>Maakunnat</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={true}
                allowSelectAll={true}
                placeholder="Valitse&hellip;"
                onChange={e => setMaakunnat(e.map(a => a.value))}
                options={[...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] }))]}
              />
            </div>
            <div style={{ width: '200px' }}>
              <Text>Henkilöstömäärä</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={false}
                onChange={e => setKoko(e.value)}
                options={[
                  { value: -1, label: 'Kaikki' },
                  { value: 0, label: '1-19' },
                  { value: 1, label: '20-49' },
                  { value: 2, label: '50-149' },
                  { value: 3, label: '150-249' },
                  { value: 4, label: '250-499' },
                  { value: 5, label: '500-999' },
                  { value: 6, label: '>1000' },
                ]}
              />
            </div>
          </Stack>

          <Heading as="h2" size="sm" mb={2}>
            Valitse vertailuryhmä 2
          </Heading>
          <Stack isInline align="flex-end">
            <div style={{ width: '200px' }}>
              <Text>Maakunnat</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={true}
                allowSelectAll={true}
                placeholder="Valitse&hellip;"
                onChange={e => setMaakunnat2(e.map(a => a.value))}
                options={[...Object.keys(Maakunnat).map(e => ({ value: e, label: Maakunnat[e] }))]}
              />
            </div>
            <div style={{ width: '200px' }}>
              <Text>Henkilöstömäärä</Text>
              <MultiSelect
                //@ts-ignore
                isMulti={false}
                onChange={e => setKoko2(e.value)}
                options={[
                  { value: -1, label: 'Kaikki' },
                  { value: 0, label: '1-19' },
                  { value: 1, label: '20-49' },
                  { value: 2, label: '50-149' },
                  { value: 3, label: '150-249' },
                  { value: 4, label: '250-499' },
                  { value: 5, label: '500-999' },
                  { value: 6, label: '>1000' },
                ]}
              />
            </div>
          </Stack>
          <Heading as="h2" size="sm" mb={2} mt={4}>
            Vertailu kysely
          </Heading>
          <Stack isInline align="flex-end">
            <div style={{ width: '200px' }}>
              <MultiSelect
                //@ts-ignore
                isMulti={false}
                placeholder="Valitse&hellip;"
                onChange={e => setCollection(e.value)}
                //@ts-ignore
                options={collections.data?.collections.map(e => ({ value: e.id, label: e.nimi }))}
              />
            </div>
            <Button disabled={!collection} aria-label="Hae" onClick={compareWithOwnSurveys}>
              Hae
            </Button>
          </Stack>
        </Box>
      </Box>
      {answerData.data && (
        <ChartLegend data={answerData.data.compare[0].roles} comparation={!!filters} />
      )}
      {answerData.data &&
        answerData.data.compare.map((item: AnswerEntity) => (
          <Box
            key={item.index}
            w="100%"
            height="350px"
            mb={12}
            borderWidth="2px"
            borderRadius="xl"
            className="printSection"
          >
            <Box height="30%" padding={2}>
              <Text align="left" fontSize="md">
                {item.index}. {item.kysymys}
              </Text>
            </Box>
            <Box height="70%" padding={1}>
              <ResultChart
                data={[item]}
                key={item.index}
                roles={answerData.data.compare[0].roles}
              />
            </Box>
          </Box>
        ))}
    </Box>
  )
}

export default AdminCompareWithFilters
