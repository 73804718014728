import { useHistory } from 'react-router-dom'
import { Button } from '@chakra-ui/react'
import pptxgen from 'pptxgenjs'
import { useCallback } from 'react'

const PowerPointGeneratorButton = ({ data }) => {
  const optsChartBar3 = {
    x: 0.5,
    y: 3.8,
    w: 6.0,
    h: 3.5,
    barDir: 'bar',

    //chartArea: { fill: { color: pptx.colors.BACKGROUND2 } },
    //chartArea: { fill: { color: "F1F1F1", transparency: 75 } },
    chartArea: { fill: { color: 'F1F1F1' }, border: { color: 'F2F2F2' } },
    plotArea: { fill: { color: 'F2F9FC' } },
    //plotArea: { border: { pt: 3, color: "CF0909" }, fill: { color: "F1C1C1" } },
    //plotArea: { border: { pt: 3, color: "CF0909" }, fill: { color: "F1C1C1", transparency: 10 } },

    catAxisLabelColor: 'CC0000',
    catAxisLabelFontFace: 'Helvetica Neue',
    catAxisLabelFontSize: 14,
    catAxisOrientation: 'minMax',

    titleColor: '33CF22',
    titleFontFace: 'Helvetica Neue',
    titleFontSize: 16,

    showTitle: true,
    title: 'Sales by Region',
  }
  const generate = useCallback(() => {
    const pres = new pptxgen()
    data.forEach(e => {
      const tmpslide = pres.addSlide({ sectionTitle: e.otsikko })
      console.log(e)
      const data = e.data.map(d => ({
        label: d.comparation ? `Vertaisarvo ${d.role.name}` : d.role.name,
        value: d.average,
        color: d.fill,
      }))
      const filteredData = data.filter(e => e.value && e.value !== 0 && e.value !== -1)

      const slideData = [
        {
          kysymys: e.kysymys,
          otsikko: e.otsikko,
          aliotsikko: e.aliotsikko,
          labels: filteredData.map(d => d.label).reverse(),
          values: filteredData.map(d => d.value).reverse(),
        },
      ]
      tmpslide.addText(
        [
          {
            text: `${slideData[0].otsikko} - ${slideData[0].aliotsikko}\n`,
            options: {
              fill: { color: 'FF0000', transparency: 50 },
              fontFace: 'Arial',
              fontSize: 17,
              isTextBox: true,
            },
          },
          {
            text: `${slideData[0].kysymys}`,
            options: {
              fill: { color: 'FF0000', transparency: 50 },
              fontFace: 'Arial',
              fontSize: 15,
              isTextBox: true,
            },
          },
        ],
        {
          w: '100%',
          fill: { color: 'FF0000', transparency: 50 },
          margin: [0.5, 0.5, 0.5, 0.5],
          valign: 'top',
        }
      )
      tmpslide.addChart(pres.ChartType.bar, slideData, {
        x: 3,
        barDir: 'bar',
        showValue: true,
        // chartColors: filteredData.map(d => d.color).reverse(),
        valAxisMinVal: 1,
        valAxisMaxVal: 4,
        valAxisMajorUnit: 1,
        dataLabelFormatCode: '0.00',
      })
    })
    pres.writeFile()
  }, [data])
  /*
              <Box mb={12} w="700px" className="printSection">
                <Text fontSize="xs" fontWeight="semibold" textTransform="uppercase">
                  {a.otsikko} &bull; {a.aliotsikko}
                </Text>
                <Heading as="h2" fontSize="xl" my={1}>
                  {a.kysymys}
                </Heading>
                <ReportItem id={a.index} queryData={a} />
              </Box>

  */
  const history = useHistory()
  return (
    <Button
      aria-label="Lataa Powerpoint"
      display="inline-block"
      mr={4}
      variant="primary"
      onClick={() => generate()}
    >
      Lataa PowerPoint
    </Button>
  )
}

export default PowerPointGeneratorButton
