import { ReactElement, useCallback, useState } from 'react'
import {
  Text,
  Heading,
  RadioGroup,
  Stack,
  Icon,
  Box,
  Spinner,
  useToast,
  Flex,
} from '@chakra-ui/react'
import { FaRegLaugh, FaRegSmile, FaRegAngry, FaRegFrown, FaQuestionCircle } from 'react-icons/fa'
import { QueryButton } from '../components/QueryButton'
import { QuestionVastausInput } from '../models/QuestionVastausInput'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { useRole } from '../contexts/RoleContext'
import { useMutation, useQuery } from '@apollo/client'
import { ALL_QUESTIONS } from '../queries/all_questions'
import { CREATE_VASTAUS } from '../mutations/create_vastaus'

interface CustomRadioButtonProps {
  radioValue: number
  value: number
  setValue: (value: number) => void
  icon: any
  isDisabled: boolean
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
  radioValue,
  value,
  setValue,
  icon,
  isDisabled,
}) => {
  const handleCustomRadioClick = useCallback(() => {
    if (value !== radioValue) {
      setValue(radioValue)
    }
  }, [value])

  return (
    <Flex
      disabled={isDisabled}
      flex="0 0 auto"
      as="button"
      onClick={handleCustomRadioClick}
      alignItems="center"
      justifyContent="center"
      _hover={{ outlineColor: 'brand.black' }}
      _active={{}}
      _focus={{}}
      bg={radioValue === value ? 'brand.secondary.200' : 'none'}
      value={4}
      boxSize={['2.5rem', '3.5rem', '4.5rem']}
      borderRadius="full"
    >
      <Icon as={icon} boxSize={['1.5rem', '2.5rem', '3.5rem']} />
    </Flex>
  )
}

function Survey(): ReactElement {
  const [index, setIndex] = useState<number>(0)
  const [answers, setAnswers] = useState<QuestionVastausInput[]>([])
  const [value, setValue] = useState<number>(0)
  const [lastQuestion, setLastQuestion] = useState<boolean>(false)
  const { role, setRole } = useRole()

  const intl = useIntl()
  const history = useHistory()
  const toast = useToast()

  const {
    loading: queryLoading,
    data: queryData,
    error: queryError,
  } = useQuery(ALL_QUESTIONS, {
    variables: {
      patteristoId: window.localStorage.getItem('patteristo'),
      language: window.localStorage.getItem('language'),
    },
  })

  const [createVastaus, { loading: mutationLoading }] = useMutation(CREATE_VASTAUS, {
    onCompleted: mutationData => {
      if (mutationData.createVastaus) {
        toast({
          title: intl.formatMessage({
            id: 'send_answers_success',
            defaultMessage: 'Vastausten lähetys onnistui!',
          }),
          status: 'success',
          duration: 5000,
        })
        setAnswers([])
        history.push('/thankyou')
      } else {
        toast({
          title: intl.formatMessage({
            id: 'send_answers_fail',
            defaultMessage: 'Vastausten lähetys epäonnistui! Avaa kysely uudelleen linkistä.',
          }),
          status: 'error',
          duration: 5000,
        })
      }
    },
    onError: mutationError => {
      console.log(JSON.stringify(mutationError, null, 2))

      return (
        <Box>
          <Text>Virhe! Fel! Error!</Text>
        </Box>
      )
    },
  })

  function handleClick() {
    const currentQuestion = queryData.collection.kysymykset[index]
    setIndex(Math.min(index + 1, queryData.collection.kysymykset.length - 1))
    const newAnswer = {
      id: currentQuestion.id,
      value: value,
    }
    setAnswers([...answers, newAnswer])
    setValue(0)

    checkForReadyToSendAnswers()
  }

  const handleBackClick = useCallback(() => {
    setIndex(Math.max(index - 1, 0))
    const newAnswers = [...answers]
    const lastAnswer = newAnswers.pop()
    setValue(lastAnswer?.value || 0)
    setAnswers(newAnswers)
  }, [index])

  function handleChange(selection: string) {
    setValue(parseInt(selection))
  }

  const sendAnswers = useCallback(() => {
    createVastaus({
      variables: {
        rooli: { id: role.id },
        patteristoId: window.localStorage.getItem('patteristo'),
        vastaukset: answers,
      },
    })
    // eslint-disable-next-line
  }, [role, answers, window.localStorage])

  function checkForReadyToSendAnswers() {
    if (index === queryData.collection.kysymykset.length - 1) {
      setLastQuestion(true)
    }
  }

  if (queryLoading) {
    return (
      <Box textAlign="center">
        <Spinner size="xl" />
      </Box>
    )
  }

  if (queryError) {
    return (
      <div>
        <h1>Virhe! Fel! Error!</h1>
      </div>
    )
  }

  const radioButtons: Array<{ icon: any; radioValue: number }> = [
    { icon: FaRegAngry, radioValue: 1 },
    { icon: FaRegFrown, radioValue: 2 },
    { icon: FaQuestionCircle, radioValue: -1 },
    { icon: FaRegSmile, radioValue: 3 },
    { icon: FaRegLaugh, radioValue: 4 },
  ]

  return (
    <>
      <Box h="50px">
        <Heading
          as="h1"
          size="xs"
          fontWeight="semibold"
          mx={12}
          pb={4}
          pt={2}
          borderBottom="1px solid #ddd"
        >
          {queryData.collection.kysymykset[index].otsikko}
        </Heading>
      </Box>
      <Box h="150px">
        <Heading as="h2" size="sm" mt={14}>
          {queryData.collection.kysymykset[index].aliotsikko}
        </Heading>
        <Text mt={4} fontSize="xl">
          {queryData.collection.kysymykset[index].kysymys}
        </Text>
      </Box>
      <RadioGroup onChange={handleChange} value={value} mt={16} mb={12}>
        <Stack justifyContent="center" spacing={[2, 3, 4]} direction="row">
          {/* <Radio value={1} isDisabled={lastQuestion}>
            <Icon as={FaRegAngry} boxSize="2.5em" />
          </Radio>
          <Radio value={2} isDisabled={lastQuestion}>
            <Icon as={FaRegFrown} boxSize="2.5em" />
          </Radio>
          <Radio value={-1} isDisabled={lastQuestion}>
            <Icon as={FaQuestionCircle} boxSize="2.5em" />
          </Radio>
          <Radio value={3} isDisabled={lastQuestion}>
            <Icon as={FaRegSmile} boxSize="2.5em" />
          </Radio>
          <Radio value={4} isDisabled={lastQuestion}>
            <Icon as={FaRegLaugh} fill="#FFF" boxSize="2.5em" />
          </Radio> */}
          {radioButtons.map(({ icon, radioValue }) => (
            <CustomRadioButton
              key={`value${radioValue}`}
              icon={icon}
              radioValue={radioValue}
              value={value}
              setValue={setValue}
              isDisabled={lastQuestion}
            />
          ))}
        </Stack>
      </RadioGroup>
      <QueryButton
        lastQuestion={lastQuestion}
        sendAnswers={sendAnswers}
        handleClick={handleClick}
        value={value}
        index={index}
        loading={mutationLoading}
        handleBackClick={handleBackClick}
      ></QueryButton>
      <Text mt={4} color="brand.gray.400">
        {index + 1}/{queryData.collection.kysymykset.length}
      </Text>
    </>
  )
}

export default Survey
