// ALFAME THEME
import { colors } from './colors'

// Properties defined in a layer style:
// - Color or text color
// - Background color
// - Border width and border color
// - Box shadow
// - Opacity
export const layerStyles = {
  navigation: {
    color: colors.brand.black,
    bg: 'colors.brand.white',
    boxShadow: 'lg',
  },
  'chart-legend': {
    bg: colors.brand.gray[50],
  },
}
