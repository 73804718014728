import { SimpleGrid } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import Card from './Card'

export function SurveyList(props) {
  const surveys = props.surveys?.patteristot
  const history = useHistory()
  const user = props.user

  return (
    <SimpleGrid minChildWidth="335px" spacing={4}>
      {surveys &&
        surveys
          .slice()
          .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
          .map(entry => <Card key={entry.id} entry={entry} user={user} />)}
    </SimpleGrid>
  )
}

export default SurveyList
