import { createContext, useContext } from 'react'

export type Role = {
  id: string
  name: string
}
export type RoleContextType = {
  role: Role
  setRole: (role: Role) => void
}

export const RoleContext = createContext<RoleContextType>({
  role: { id: '-1', name: '' },
  setRole: role => console.warn('no role provided'),
})

export const useRole = () => {
  return useContext(RoleContext)
}
