import { createContext, useContext } from 'react'

export type PatteristoContextType = {
  // prettier-ignore
  patteristo: string,
  setPatteristo: (Patteristo: string) => void
}

export const PatteristoContext = createContext<PatteristoContextType>({
  patteristo: '',
  setPatteristo: patteristo => console.warn('no patteristo provided'),
})

export const usePatteristo = () => useContext(PatteristoContext)
