import { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { VStack, Button, useToast, Text, Heading, Link } from '@chakra-ui/react'
import { PasswordInput } from '../components/PasswordInput'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLazyQuery } from '@apollo/client'
import { CHECK_PASSWORD } from '../queries/check_password'

function Landing(props): ReactElement {
  const id = useParams<Record<string, string>>()

  const [password, setPassword] = useState<string>('')
  const [surveyId, setSurveyId] = useState<string>(id.id)

  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()

  const [getPassword, { loading, data, error }] = useLazyQuery(CHECK_PASSWORD)
  const checkPassword = async () => {
    await getPassword({ variables: { id: surveyId, salasana: password } })
    // if(data.checkPassword)) {
    //   // setPatteristo(surveyId)
    // } else {
    //   toast({
    //     title: intl.formatMessage({
    //       id: 'wrong_password',
    //       defaultMessage: 'Väärä salasana',
    //     }),
    //     status: 'error',
    //     duration: 5000,
    //     isClosable: true,
    //   })
    // }
  }

  useEffect(() => {
    if (id.pw) {
      setPassword(id.pw)
    }
  }, [id.pw])
  useEffect(() => {
    if (id.pw && password === id.pw) {
      checkPassword()
    }
  }, [password, id.pw])
  useEffect(() => {
    if (error) {
      toast({
        title: intl.formatMessage({
          id: 'survey_ended',
          defaultMessage: 'Kysely umpeutunut',
        }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    if (data?.testPassword) {
      window.localStorage.setItem('patteristo', surveyId)
      if (id.pw) {
        history.push({
          pathname: '/roleselection',
          state: {
            displayLangSwitcher: true,
          },
        })
      } else {
        history.push({
          pathname: 'roleselection',
          state: {
            displayLangSwitcher: false,
          },
        })
      }
    } else if (password.length > 0) {
      toast({
        title: intl.formatMessage({
          id: 'wrong_password',
          defaultMessage: 'Väärä salasana',
        }),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line
  }, [data, error])

  function handleKeyDown(e: { key: string }) {
    if (e.key == 'Enter') {
      checkPassword()
    }
  }

  if (id.id) {
    return (
      <VStack spacing={8} pt={8}>
        {/* <LangSwitcher setLanguage={props.setLanguage} /> */}
        <Heading as="h1" fontSize="2xl">
          <FormattedMessage id="welcome" defaultMessage="Tervetuloa Alfame Pulseen!" />
        </Heading>
        {/* <Text>Kyselyn tunnus: {id.id}</Text> */}
        <PasswordInput setPassword={setPassword} handleKeyDown={handleKeyDown} />
        <Button aria-label="Aloita" variant="primary" onClick={checkPassword}>
          <FormattedMessage id="start" defaultMessage="Aloita" />
        </Button>
      </VStack>
    )
  }

  return (
    <VStack textAlign="left" marginLeft={400} marginRight={400} spacing={8} pt={8}>
<Heading>Tervetuloa Alfame Pulsen käyttäjäksi!</Heading>
<Text textAlign="left">
Alfame Pulseen rekisteröidytään sähköpostiosoitteella ja yrityksen tunnuksella.
</Text>
<Text textAlign="left">
Alfame Pulse on tarkoitettu vain tietyille rekisteröityneille organisaatioille ja niiden edustajille.
</Text>
<Text textAlign="left">
Lisätietoa Alfame Pulsen mittarista saa ryhmän yhteyshenkilöltä.
</Text>
    </VStack>
  )
}

export default Landing
