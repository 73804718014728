import { Route, Redirect } from 'react-router-dom'
import { useAuthState } from '../contexts/Firebase/Context'
function PrivateRoute({ children, ...rest }) {
  const authState = useAuthState()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        // I think here we should check if authed from firebase, not if token
        authState.isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
