import { gql } from '@apollo/client'

export const UPDATE_SURVEY = gql`
  mutation updateSurvey($patteristoId: String!, $deadline: DateTime!, $maxVastaukset: Float!) {
    updatePatteristo(
      data: { patteristoId: $patteristoId, deadline: $deadline, maxVastaukset: $maxVastaukset }
    ) {
      id
    }
  }
`
