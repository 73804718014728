import React from 'react'
import PropTypes from 'prop-types'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'

interface Props {
  currentPage: number
  totalPages: number
  handleNextPage: (page: number) => void
  handlePrevPage: (page: number) => void
}
const Pagination: React.FC<Props> = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
}) => {
  return (
    <div className="pagination-button-wrapper">
      <button
        aria-label="Pagination"
        className="pagination-button"
        onClick={() => handlePrevPage(currentPage)}
        disabled={currentPage === 1}
      >
        <ArrowBackIcon />
      </button>

      <span className="pagination-page-info">
        Sivu {currentPage} / {totalPages}
      </span>

      <button
        aria-label="Pagination"
        className="pagination-button"
        onClick={() => handleNextPage(currentPage)}
        disabled={currentPage === totalPages}
      >
        <ArrowForwardIcon />
      </button>
    </div>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePrevPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
}

export default Pagination
