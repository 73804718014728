import { Table, Thead, Tbody, Tr, Th, Td, Box } from '@chakra-ui/react'

export function ResultTable(props) {
  interface Result {
    name: string
    all: number
    results: { [key: number]: number }
    // other properties
  }

  const resultObject: Result[] = []

  const propsData = props.data

  propsData.forEach(item => {
    const newObject: Result = {
      name: item.role.name,
      all: item.roleCount,
      results: {
        '-1': 0,
        '0': 0,
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
      },
    }

    item.values.forEach(value => {
      const key = value.key
      const count = value.count

      newObject.results[key] = count
    })
    resultObject.push(newObject)
  })

  return (
    <Box>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th></Th>
            <Th textAlign="right">Erittäin huonosti</Th>
            <Th textAlign="right">Huonosti</Th>
            <Th textAlign="right">En osaa sanoa</Th>
            <Th textAlign="right">Hyvin</Th>
            <Th textAlign="right">Erittäin hyvin</Th>
            <Th textAlign="right">Yht</Th>
          </Tr>
        </Thead>
        <Tbody>
          {resultObject.map((obj, index) => (
            <Tr key={index}>
              <Td isNumeric>{obj.name}</Td>
              <Td isNumeric>{obj.results[1]}</Td>
              <Td isNumeric>{obj.results[2]}</Td>
              <Td isNumeric>{obj.results[-1]}</Td>
              <Td isNumeric>{obj.results[3]}</Td>
              <Td isNumeric>{obj.results[4]}</Td>
              <Td isNumeric>{obj.all}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}
