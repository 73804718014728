import { ReactElement, useState } from 'react'
import {
  Box,
  Stack,
  Wrap,
  Button,
  Heading,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  useToast,
} from '@chakra-ui/react'
import Label from '../components/Label'
import { useHistory } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import fi from 'date-fns/locale/fi'
import { useQuery } from '@apollo/client'

import 'react-datepicker/dist/react-datepicker.css'
import { useMutation } from '@apollo/client'
import { UPDATE_SURVEY } from '../mutations/update_survey'
import { DELETE_PATTERISTO } from '../mutations/delete_patteristo'
import { ME } from '../queries/me'

registerLocale('fi', fi)
setDefaultLocale('fi')

function EditSurvey(params): ReactElement {
  const [coachingDay, setCoachingDay] = useState<boolean>(false)

  const [updateSurvey, { loading: mutationLoading }] = useMutation(UPDATE_SURVEY)
  const [deletePatteristo, { loading: deleteMutationLoading }] = useMutation(DELETE_PATTERISTO)
  const { data } = useQuery(ME)

  const history = useHistory()
  const toast = useToast()
  const intl = useIntl()
  //@ts-ignore
  //@ts-ignore
  const [maxAnswers, setMaxAnswers] = useState<number>(history.location.state.maxAnswers)
  //@ts-ignore
  const [endDate, setEndDate] = useState<Date>(new Date(history.location.state.deadline))

  function handleDate(date) {
    setEndDate(new Date(date))
  }

  const send = async () => {
    try {
      await updateSurvey({
        variables: {
          //@ts-ignore
          patteristoId: history.location.state.id,
          deadline: endDate,
          maxVastaukset: maxAnswers,
        },
      })
    } catch (e) {
      console.log(e)
      console.log(JSON.stringify(e, null, 2))
    }
    history.push('/admin')
    history.go(0)
  }

  const deleteCollection = async () => {
    try {
      await deletePatteristo({
        variables: {
          //@ts-ignore
          id: history.location.state.id,
        },
      })
    } catch (e) {
      console.log(e)
      console.log(JSON.stringify(e, null, 2))
    }
    history.push('/admin')
    history.go(0)
  }

  return (
    <Box textAlign="left">
      <Heading as="h1" mb={4}>
        <FormattedMessage id="editSurvey" defaultMessage="Muokkaa kyselyä" />
      </Heading>
      <Box borderWidth="1px" borderRadius="lg" fontSize="sm" maxW="500px">
        <Stack p={4} spacing={4}>
          <Box>
            <Label
              text={intl.formatMessage({
                id: 'max_number_answers',
                defaultMessage: 'Vastausten maksimimäärä',
              })}
            />
            <NumberInput
              w={24}
              size="sm"
              defaultValue={maxAnswers}
              min={0}
              onChange={maxAnswersString => setMaxAnswers(parseInt(maxAnswersString))}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box>
            <Label
              text={intl.formatMessage({
                id: 'survey_open_until',
                defaultMessage: 'Viimeinen vastauspäivä',
              })}
            />
            <Box borderWidth="1px" borderRadius="sm" p={2} w={44}>
              <DatePicker
                locale="fi"
                dateFormat="d.M.yyyy"
                selected={endDate}
                onChange={handleDate}
              />
            </Box>
          </Box>
        </Stack>
        {data.me.isAdmin && (
          <Wrap spacing={4} px={4} py={3} borderTopWidth="1px">
            <Button aria-label="Tallenna" variant="primary" onClick={send}>
              <FormattedMessage id="save" defaultMessage="Tallenna" />
            </Button>
            <Button aria-label="Poista kysely" variant="primary" onClick={deleteCollection}>
              <FormattedMessage id="delete" defaultMessage="Poista kysely" />
            </Button>
          </Wrap>
        )}
      </Box>
    </Box>
  )
}

export default EditSurvey
