import { extendTheme } from '@chakra-ui/react'
import { StepsStyleConfig as Steps } from 'chakra-ui-steps'

import { colors } from './colors'
import Link from './components/link'
import Button from './components/button'
import Table from './components/table'
import { layerStyles } from './layerStyles'

const theme = extendTheme({
  colors,
  layerStyles,
  components: {
    Steps,
    Link,
    Button,
    Table,
  },
})

export default theme
