import { gql } from '@apollo/client'

export const TES = gql`
  query tes {
    tess {
      id
      name
    }
  }
`
