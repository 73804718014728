import { Heading, Text, Stack, VStack, Icon, Button } from '@chakra-ui/react'
import { FaRegLaugh, FaRegSmile, FaRegAngry, FaRegFrown, FaQuestionCircle } from 'react-icons/fa'
import { Link as RouterLink } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { ReactElement } from 'react'

function Guide(): ReactElement {
  const intl = useIntl()

  return (
    <VStack spacing={8}>
      <Heading as="h1">
        {intl.formatMessage({
          id: 'guideHeading',
          defaultMessage: 'Vastausohjeet',
        })}
      </Heading>
      <Text>
        {intl.formatMessage({
          id: 'guideLead',
          defaultMessage:
            'Arvioi tässä kyselyssä esitettyjen väitteiden paikkansa pitävyyttä seuraavalla vastausasteikolla:',
        })}
      </Text>
      <Stack spacing={4} borderWidth="1px" borderRadius="lg" p="6" textAlign="left">
        <Text d="flex" alignItems="center">
          <Icon as={FaRegLaugh} boxSize="2.5em" mr={4} rounded="full" />
          {intl.formatMessage({
            id: 'guideOption1',
            defaultMessage: 'Täysin samaa mieltä',
          })}
        </Text>

        <Text d="flex" alignItems="center">
          <Icon as={FaRegSmile} boxSize="2.5em" mr={4} rounded="full" />
          {intl.formatMessage({
            id: 'guideOption2',
            defaultMessage: 'Jokseenkin samaa mieltä',
          })}
        </Text>

        <Text d="flex" alignItems="center">
          <Icon as={FaQuestionCircle} boxSize="2.5em" mr={4} rounded="full" />
          {intl.formatMessage({
            id: 'guideOptionEOS',
            defaultMessage: 'En osaa sanoa',
          })}
        </Text>

        <Text d="flex" alignItems="center">
          <Icon as={FaRegFrown} boxSize="2.5em" mr={4} rounded="full" />
          {intl.formatMessage({
            id: 'guideOption3',
            defaultMessage: 'Jokseenkin eri mieltä',
          })}
        </Text>

        <Text d="flex" alignItems="center">
          <Icon as={FaRegAngry} boxSize="2.5em" mr={4} rounded="full" />
          {intl.formatMessage({
            id: 'guideOption4',
            defaultMessage: 'Täysin eri mieltä',
          })}
        </Text>
      </Stack>
      <Button aria-label="Seuraava" as={RouterLink} to="/survey" variant="primary">
        <FormattedMessage id="nextQuestion" defaultMessage="Seuraava" />
        &rarr;
      </Button>
    </VStack>
  )
}

export default Guide
