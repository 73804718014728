import { gql } from '@apollo/client'

export const SINGLE_ORGANIZATION = gql`
  query getOrganization($organizationId: String!) {
    organization(id: $organizationId) {
      id
      nimi
      maakunta
      koko
      tes {
        id
        name
      }
      users {
        id
        etunimi
        sukunimi
        email
      }
    }
  }
`
