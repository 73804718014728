import {
  Wrap,
  Image,
  WrapItem,
  Spacer,
  Text,
  HStack,
  IconButton,
  useDisclosure,
  Flex,
  Tooltip,
  Box,
} from '@chakra-ui/react'

import TitleText from './TitleText'
import Logout from './Logout'
import AdminPanelButton from './AdminPanelButton'
import { useEffect, useState } from 'react'
import { EditIcon } from '@chakra-ui/icons'
import { UpdateOrganizationModal } from '../components/UpdateOrganizationModal'
import { gql, useMutation, useQuery } from '@apollo/client'
import { UPDATE_ORGANIZATION } from '../mutations/update_organization'
import { ALL_ORGANIZATIONS } from '../queries/organizations'
import { CREATE_USER } from '../mutations/create_user'
import { REMOVE_USER_FROM_ORGANIZATION } from '../mutations/remove_user_from_organization'
import { ADD_USER_TO_ORGANIZATION } from '../mutations/add_user_to_organization'
import { FaInfoCircle } from 'react-icons/fa'

const Navigation = ({
  user,
  authentication,
  currentOrganization,
  userData,
  fetchCurrentOrganization,
  setCurrentOrganization,
}) => {
  const { refetch: userRefetch } = useQuery(
    gql`
      query users {
        users {
          id
          email
          etunimi
          sukunimi
          organisaatiot {
            id
            nimi
          }
          isAdmin
          active
        }
      }
    `,
    { fetchPolicy: 'cache-and-network', pollInterval: 60000 }
  )
  const logo = '/images/logo.png'
  const [logoProvided, setLogoProvided] = useState(logo.length > 0)
  const [updateOrganizationId, setUpdateOrganizationId] = useState({ id: null, type: '' })
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [organisaatiot, setOrganisaatiot] = useState<any[]>([])
  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION)
  const { data, refetch } = useQuery(ALL_ORGANIZATIONS)
  const [createUser, { loading: mutationLoading }] = useMutation(CREATE_USER)
  const [removeUserFromOrganizationMutation] = useMutation(REMOVE_USER_FROM_ORGANIZATION)
  const [addUserToOrganization] = useMutation(ADD_USER_TO_ORGANIZATION)

  useEffect(() => {
    if (data) {
      setOrganisaatiot([...data.organizations.organizations])
    }
  }, [data])

  return (
    <HStack p="3" id="navigation" layerStyle="navigation" spacing={4} align="center">
      <UpdateOrganizationModal
        updateOrganizationId={updateOrganizationId}
        fetchCurrentOrganization={fetchCurrentOrganization}
        organisaatiot={organisaatiot}
        setCurrentOrganization={setCurrentOrganization}
        currentOrganization={currentOrganization}
        isOpen={isOpen}
        onClose={onClose}
        userData={userData}
        updateOrganization={updateOrganization}
        refetch={refetch}
        createUser={createUser}
        userRefetch={userRefetch}
        removeUserFromOrganizationMutation={removeUserFromOrganizationMutation}
        addUserToOrganization={addUserToOrganization}
      />
      {logoProvided && (
        <Image
          height={['20px', '40px', '60px']}
          src={logo}
          onError={() => setLogoProvided(false)}
        ></Image>
      )}
      <TitleText user={user} />
      {authentication.authenticated && <Text ml={1}>{currentOrganization.nimi}</Text>}
      <Spacer></Spacer>
      {userData?.isAdmin && <AdminPanelButton />}
      <Logout />
      {user && currentOrganization && (
        <Flex>
          <Box mt={2}>
            <Tooltip
              label="Tästä linkistä pääset muokkaamaan organisaatiosi tietoja ja lisäämään tai poistamaan käyttäjiä."
              placement="top"
            >
              <span>
                <FaInfoCircle />
              </span>
            </Tooltip>
          </Box>
          <Box pl={2}>
            <IconButton
              aria-label="Edit"
              onClick={() => {
                setUpdateOrganizationId({
                  id:
                    organisaatiot.find((e: any) => e.nimi === currentOrganization.nimi)?.id ||
                    currentOrganization.id,
                  type: 'organization',
                })
                onOpen()
              }}
              icon={<EditIcon />}
            />
          </Box>
        </Flex>
      )}
    </HStack>
  )
}

export default Navigation
