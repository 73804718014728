enum Maakunnat {
  AHVENANMAA = 'Ahvenanmaa',
  ETELAKARJALA = 'Etelä-Karjala',
  ETELAPOHJANMAA = 'Etelä-Pohjanmaa',
  ETELASAVO = 'Etelä-Savo',
  KAINUU = 'Kainuu',
  KANTAHAME = 'Kanta-Häme',
  KESKIPOHJANMAA = 'Keski-Pohjanmaa',
  KESKISUOMI = 'Keski-Suomi',
  KYMENLAASKO = 'Kymenlaakso',
  LAPPI = 'Lappi',
  PIRKANMAA = 'Pirkanmaa',
  POHJANMAA = 'Pohjanmaa',
  POHJOISKARJALA = 'Pohjois-Karjala',
  POHJOISPOHJANMAA = 'Pohjois-Pohjanmaa',
  POHJOISSAVO = 'Pohjois-Savo',
  PAIJATHAME = 'Päijät-Häme',
  SATAKUNTA = 'Satakunta',
  UUSIMAA = 'Uusimaa',
  VARSINAISSUOMI = 'Varsinais-Suomi',
}

export default Maakunnat
