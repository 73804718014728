import { gql } from '@apollo/client'

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $etunimi: String!
    $sukunimi: String!
    $isAdmin: Float
    $isActive: Boolean
  ) {
    createUser(
      data: {
        email: $email
        etunimi: $etunimi
        sukunimi: $sukunimi
        isAdmin: $isAdmin
        active: $isActive
      }
    ) {
      id
    }
  }
`
