import { ReactElement, useState } from 'react'
import { ChakraProvider, Box, Text, VStack, Grid, Button } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePatteristo } from '../contexts/PatteristoContext'

function FinishSurvey(): ReactElement {
  const [cleared, setCleared] = useState<Boolean>(false)
  const { patteristo } = usePatteristo()
  const intl = useIntl()

  if (window.localStorage.patteristo.length === 0 || cleared === true) {
    return (
      <ChakraProvider>
        <Box textAlign="center" fontSize="lg">
          <Grid minH="100vh" p={3}>
            <Text>
              <FormattedMessage
                id="closeWindow"
                defaultMessage="Nyt voit sulkea välilehden. Muista myös tyhjentää selaimen välimuisti."
              />
            </Text>
          </Grid>
        </Box>
      </ChakraProvider>
    )
  }

  return (
    <Box textAlign="center" fontSize="lg">
      <Grid minH="100vh" p={3}>
        <VStack spacing={8}>
          <Text>
            <FormattedMessage id="thankyou" defaultMessage="Kiitos kyselyn täyttämisestä!" />
          </Text>
          <Button
            aria-label="Restart"
            as={RouterLink}
            to={`/${localStorage.getItem('patteristo')}`}
          >
            <FormattedMessage id="restart" defaultMessage="Uusi vastaaja" />
          </Button>
          <Text>
            <FormattedMessage id="or" defaultMessage="tai" />
          </Text>
          <Button
            aria-label="Tyhjennä"
            onClick={() => {
              window.localStorage.setItem('patteristo', '')
              setCleared(true)
            }}
          >
            <FormattedMessage id="logout" defaultMessage="Kirjaudu ulos" />
          </Button>
        </VStack>
      </Grid>
    </Box>
  )
}

export default FinishSurvey
