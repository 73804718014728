import { gql } from '@apollo/client'

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $id: String!
    $nimi: String
    $maakunta: Maakunnat
    $koko: Float
    $active: Boolean
    $ytunnus: String
    $tes: [IdInput!]
  ) {
    updateOrganization(
      data: {
        id: $id
        nimi: $nimi
        maakunta: $maakunta
        koko: $koko
        active: $active
        ytunnus: $ytunnus
        tes: $tes
      }
    ) {
      id
    }
  }
`
